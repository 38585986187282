import { Type } from 'class-transformer';
import { CaseService } from './case-service';
import { CaseServiceCollection } from './case-services';

export class Authorization {
  public readonly requested?: boolean;
  public readonly completedOn?: Date;
  public readonly completedBy?: string;
}

export class MedicolegalInvestigation extends CaseService {
  public readonly cremationViewing?: Authorization;

  constructor(args: Partial<MedicolegalInvestigation>) {
    super(args);
    this.cremationViewing = args?.cremationViewing;
  }
}

export class MedicolegalInvestigationCollection extends CaseServiceCollection<MedicolegalInvestigation> {
  @Type(() => MedicolegalInvestigation)
  public readonly instances!: MedicolegalInvestigation[];

  constructor(args?: Partial<MedicolegalInvestigationCollection>) {
    super(args);
    this.instances = args?.instances
      ? args.instances.map((i) => new MedicolegalInvestigation(i))
      : [];
  }
}
