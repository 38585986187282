import { Age } from '../common';
import {
  FormatDate,
  FormatDateTime,
  IsFormatDate,
  IsFormatDateTime,
} from '../dates';

export class DecedentInfo {
  public alias?: string;

  public firstName?: string;

  public middleName?: string;

  public lastName?: string;

  @IsFormatDate
  public dateOfBirth?: FormatDate;

  public age?: Age;

  @IsFormatDateTime
  public dateOfDeath?: FormatDateTime;

  public genderId?: string;

  public medicalRecordNumber?: string;

  public readonly alternateMedicalRecordNumber?: string;

  public socialSecurityNumber?: string;

  public weight?: number;

  public raceId?: string;

  public ethnicityId?: string;

  constructor(args?: Partial<DecedentInfo>) {
    this.alias = args?.alias;
    this.firstName = args?.firstName;
    this.middleName = args?.middleName;
    this.lastName = args?.lastName;
    this.dateOfBirth = args?.dateOfBirth
      ? new FormatDate(args?.dateOfBirth)
      : undefined;
    this.dateOfDeath = args?.dateOfDeath
      ? new FormatDateTime(args?.dateOfDeath)
      : undefined;
    this.genderId = args?.genderId;
    this.medicalRecordNumber = args?.medicalRecordNumber;
    this.alternateMedicalRecordNumber = args?.alternateMedicalRecordNumber;
    this.socialSecurityNumber = args?.socialSecurityNumber;
    this.weight = args?.weight;
    this.raceId = args?.raceId;
    this.ethnicityId = args?.ethnicityId;
  }

  public get displayName() {
    return (this.alias || `${this.firstName} ${this.lastName}`).trim();
  }
}
