import { Type } from 'class-transformer';
import { FormatDateTime, IsFormatDateTime } from '../../dates';

export class CaseServiceNotification {
  public readonly executed?: boolean;

  @IsFormatDateTime
  public readonly dateTime?: FormatDateTime;

  public readonly recipient?: string;

  constructor(args?: Partial<CaseServiceNotification>) {
    this.executed = args?.executed;
    this.dateTime = args?.dateTime
      ? new FormatDateTime(args.dateTime)
      : undefined;
    this.recipient = args?.recipient;
  }
}

export abstract class CaseService {
  public readonly id?: string;

  public readonly statusId?: string;

  public readonly assignee?: string;

  public readonly conductingOrganizationId?: string;

  public readonly conductingOrganizationCaseId?: string;

  @Type(() => CaseServiceNotification)
  public readonly conductingOrganizationNotification?: CaseServiceNotification;

  public readonly location?: string;

  @IsFormatDateTime
  public readonly dateTimeScheduled?: FormatDateTime;

  @IsFormatDateTime
  public readonly dateTimeCompleted?: FormatDateTime;

  public readonly details?: string;

  constructor(args?: Partial<CaseService>) {
    this.id = args?.id;
    this.statusId = args?.statusId;
    this.assignee = args?.assignee;
    this.conductingOrganizationId = args?.conductingOrganizationId;
    this.conductingOrganizationCaseId = args?.conductingOrganizationCaseId;
    this.conductingOrganizationNotification = args?.conductingOrganizationNotification
      ? new CaseServiceNotification(args.conductingOrganizationNotification)
      : undefined;
    this.location = args?.location;
    this.dateTimeScheduled = args?.dateTimeScheduled
      ? new FormatDateTime(args.dateTimeScheduled)
      : undefined;
    this.dateTimeCompleted = args?.dateTimeCompleted
      ? new FormatDateTime(args.dateTimeCompleted)
      : undefined;
    this.details = args?.details;
  }
}
